<template>
  <div>
    <v-btn @click="dialog = true" small text
      ><v-icon class="mr-2">mdi-truck-cargo-container</v-icon>RMA Items</v-btn
    >
    <v-dialog v-model="dialog" persistent width="1200">
      <v-card>
        <v-card-title class="d-flex pa-5">
          <h2>RMA Items Table</h2>
          <v-spacer></v-spacer>
          <v-btn small color="success" class="mr-2" @click="print_all()"
            >Print All</v-btn
          >
          <v-btn small color="success" class="mr-2" @click="print_multi()"
            >Print Multiple</v-btn
          >
          <v-btn
            small
            class="mr-2"
            v-if="print_multiple"
            @click="print_selected_multiple()"
            >Print Selected - {{ checkbox_array.length }}</v-btn
          >
          <add-return-item-modal />
        </v-card-title>
        <v-card-text>
          <h3 class="mb-2">Pending Replacements</h3>
          <v-text-field label="Search" v-model="pendingSearch"></v-text-field>
          <v-data-table
            :search="pendingSearch"
            dense
            :headers="returned_items_headers"
            :items="pending_returns"
          >
            <template v-slot:item.action="{ item }">
              <div class="d-flex">
                <v-btn icon color="success" @click="change_status(item)"
                  ><v-icon>mdi-check-bold</v-icon></v-btn
                >
                <edit-return-item-modal :item="item" />
                <v-btn icon color="green" @click="print(item)"
                  ><v-icon>mdi-printer</v-icon></v-btn
                >
                <v-btn icon color="red" @click="del(item)" :loading="loading">
                  <v-icon>mdi-delete</v-icon></v-btn
                >
              </div>
            </template>

            <template v-slot:item.check_box="{ item }" v-if="print_multiple">
              <div>
                <v-checkbox @click="add_checkbox(item)"></v-checkbox></div
            ></template>
          </v-data-table>

          <h3 class="mb-2">Completed Replacements</h3>
          <v-text-field
            label="Search"
            v-model="completedReplacementsSearch"
          ></v-text-field>
          <v-data-table
            dense
            :headers="completed_items_headers"
            :items="completed_returns"
            :search="completedReplacementsSearch"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit" :loading="loading"
            >Submit</v-btn
          >
          <v-btn @click="dialog = false"> Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption">returnedItemsModal.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import addReturnItemModal from "./addReturnItemModal.vue";
import EditReturnItemModal from "./editReturnItemModal.vue";
export default {
  components: { addReturnItemModal, EditReturnItemModal },
  data() {
    return {
      completedReplacementsSearch: "",
      pendingSearch: "",
      print_multiple: false,
      checkbox_array: [],
      dialog: false,
      loading: false,
      returned_items_headers: [
        { text: "", value: "check_box" },
        { text: "", value: "action" },
        { text: "ID", value: "id" },
        { text: "Item Name", value: "item.item_name" },
        { text: "Batch Import", value: "item.import_batch.batch_description" },
        // { text: "Supplier", value: "item.supplier.supplier_name" },
        { text: "S/N", value: "product_serial_number" },

        { text: "Date Marked", value: "created_at" },
        { text: "Date Returned", value: "date_returned" },
      ],
      completed_items_headers: [
        { text: "ID", value: "id" },
        { text: "Item Name", value: "item.item_name" },
        { text: "Batch Import", value: "item.import_batch.batch_description" },
        // { text: "Supplier", value: "item.supplier.supplier_name" },
        { text: "S/N", value: "product_serial_number" },

        { text: "Date Marked", value: "created_at" },
        { text: "Date Returned", value: "date_returned" },
        { text: "Date Replaced", value: "date_replaced" },
      ],
    };
  },
  computed: {
    pending_returns() {
      let pending_returns = this.returned_items.filter((p) => {
        return p.date_replaced == null;
      });
      return pending_returns;
    },
    completed_returns() {
      let completed_returns = this.returned_items.filter((p) => {
        return p.date_replaced != null;
      });
      return completed_returns;
    },
    ...mapGetters({
      returned_items: "item/returned_items",
      default_server_url: "auth/default_server_url",
    }),
  },
  methods: {
    change_status(item) {
      let date_replaced = moment().format("Y-M-D");
      let x = window.confirm(
        "Are you sure you want to complete this transaction?"
      );

      if (x) {
        console.log(item);
        item.date_replaced = date_replaced;
        this.edit_returned_item(item).then(() => {
          alert("Sucessfully edited a row!");
        });
      }
    },
    print_all() {
      let url = this.default_server_url + "/print_all_returned_items";
      window.open(url);
    },
    print_selected_multiple() {
      let url =
        this.default_server_url +
        "/print_multiple_returned_items/" +
        JSON.stringify(this.checkbox_array);
      window.open(url);
    },
    print_multi() {
      this.print_multiple = !this.print_multiple;
      this.checkbox_array = [];
    },
    add_checkbox(item) {
      const itemId = item.id;
      // Check if the item ID already exists in the array
      if (!this.checkbox_array.includes(itemId)) {
        this.checkbox_array.unshift(itemId);
        console.log(this.checkbox_array);
      } else {
        let filtered = this.checkbox_array.filter((i) => i !== itemId);
        this.checkbox_array = filtered;
        console.log(this.checkbox_array);
      }
    },

    print(item) {
      var url = this.default_server_url + "/print_item_for_return/" + item.id;

      window.open(url);
    },
    view_problem(item) {
      alert(item.problem_description);
    },
    del(item) {
      this.loading = true;
      let x = window.confirm("Are you sure you want to delete this item?");
      if (x) {
        this.delete_returned_item(item.id).then(() => {
          alert("Item successfully deleted");
        });
      }
      this.loading = false;
    },
    submit() {
      return;
    },
    ...mapActions({
      get_returned_items: "item/get_returned_items",
      delete_returned_item: "item/delete_returned_item",
      edit_returned_item: "item/edit_returned_item",
    }),
  },
  created() {
    if (this.returned_items.length <= 0) this.get_returned_items();
  },
};
</script>

<style lang="scss" scoped></style>
